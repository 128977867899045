<template>
  <div class="lecturer">
    <!-- 搜索结果 -->
    <ul class="result-list">
      <li class="result-info" v-for="item in resultsList" :key="item.id">
        <p class="img result-img"  @click="goHomePage(item)">
          <img v-if="item.avatar" :src="item.avatar">
          <img v-else src="@/assets/per_img.png">
        </p>
        <div class="result-content">
          <div class="result-name" @click="goHomePage(item)">
            <span>{{item.name}}</span>
            <!-- <div class="marks">
              <div class="mark">{{item.level}}</div>
            </div> -->
          </div>
          <div class="marks"  @click="goHomePage(item)">
            <div class="mark" v-for="mark in item.teacher_tags" :key="mark">{{mark}}</div>
          </div>
          <div class="result-describe"  @click="goHomePage(item)">{{item.introduce}}</div>
          <div class="result-video" v-if="item.course && item.course.name" @click="goCourse(item.course)">{{item.course.name}}</div>
        </div>
        <div class="toIndex"><router-link :to="'/lecturerHomepage/'+item.data_id">进入主页</router-link></div>
      </li>
    </ul>
    <!-- <pagination v-if="num>0" :num="num" :limit="limit" @getNew="getNew"></pagination> -->
  </div>
</template>
<script>
import pagination from "@/components/pc/pagination.vue";
export default {
  name: 'lecturer',
  data(){
    return {
      searchParams: {},  //搜索条件列表
      resultsList: '',   //搜索结果列表
      num: 0,            //数据总个数
      limit: 10,         //一页显示几个
      nowPage: 1,        //当前页码
      industry: 0,       //领域
      level: 0           //等级
    }
  },
  components:{
    pagination
  },
  mounted(){
    this.titleName = this.$route.query.name;
    this.module_id = this.$route.query.id;
    this.getResultsList();      //获取讲师列表
  },
  methods: {
    async getResultsList() {
      let data = await this.$axios.post(`/v1/home/moduleData`, {
        id: this.module_id,
      });
      this.resultsList = data.data;
    },
    goHomePage(item){
      this.$router.push('/lecturerHomepage/'+item.data_id)
    },
    // 跳转到课程页
    goCourse(item){
      if(item.course_id){
        this.$router.push('/recordedInfo/'+item.course_id)
      }
    },
    //翻页
    getNew(nowPage) {
      this.nowPage = nowPage;
      this.getResultsList();
    },
  }
}
</script>
<style scoped lang="scss">
.lecturer{
  background: #f9f9f9;
}
.popularity-content{
  width: 1200px;
  padding:34px 0 44px;
  background-color: #fff;
  margin: 0 auto;
}
.popularity-title{
  font-size: 24px;
  font-weight: 800;
  color: #333;
  letter-spacing: 1px;
  display: flex;
  align-items: center;
  margin-bottom: 44px;
}
.popularity-tip{
  font-size: 16px;
  color: #666;
  margin-left: 14px;
}
.popularity-list{
  display: flex;
  justify-content: space-between;
}
.popularity-info{
  width: 220px;
  text-align: center;
}
.popularity-img{
  width: 100%;
  height: 290px;
  margin-bottom: 14px;
}
.popularity-name{
  font-size: 24px;
  font-weight: 900;
  color: #333;
  letter-spacing: 1px;
  margin-bottom: 12px;
}
.popularity-the-title{
  font-size: 18px;
  color: #666;
}
.filtrate{
  padding: 20px 0 0;
  background-color: #fff;
}
.filtrate-list{
  align-items: flex-start;
  white-space: nowrap;
}
.filtrate-list:first-child{
  border-bottom: 1px solid #E5E5E5;
}
.filtrate-options{
  display: flex;
  flex-wrap: wrap;
}
.filtrate-option{
  margin-bottom: 10px;
}
.result-list{
  width: 1200px;
  margin: 0 auto;
  padding-top: 24px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  .result-info{
    box-sizing: border-box;
    width: 588px;
    padding: 10px;
    background-color: #FFF;
    box-shadow: 0px 0px 14px 0px rgba(0, 0, 0, 0.14);
    border-radius: 6px;
    overflow: hidden;
    position: relative;
    margin-bottom: 24px;
  }
  .result-img{
    width: 220px;
    height: 220px;
    float: left;
    overflow: hidden;
    margin-right: 14px;
    img{
      width: 100%;
      border-radius: 6px;
    }
  }
  .result-content{
    width: 320px;
    float: left;
  }
  .result-name{
    padding-top: 15px;
    font-size: 16px;
    font-weight: 800;
    color: #333;
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    cursor: default;
    .marks{
      margin: 0 0 0 14px;
      .mark{
        background: rgba(255, 195, 11, 0.14);
        color: #F5A623;
      }
    }
  }
  .result-describe{
    height: 48px;
    font-size: 14px;
    color: #999;
    line-height: 24px;
    //多行省略号
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
    margin-bottom: 35px;
    cursor: default;
  }
  .result-video{
    box-sizing: border-box;
    width: 100%;
    padding-left: 20px;
    min-height: 16px;
    font-size: 12px;
    color: #999;
    background: url(~@/assets/play-ico.png) no-repeat left center;
    background-size: 16px;
    cursor: pointer;
  }
  .toIndex{
    min-height: 12px;
    padding-right: 24px;
    background: url(~@/assets/arrows-right-blue-ico.png) no-repeat right center;
    background-size: 14px 12px;
    position: absolute;
    right: 25px;
    bottom: 20px;
    a{
      font-size: 14px;
      color: #254ED4;
    }
  }
}
.page-wrap{
  padding-bottom: 30px;
}
</style>